.resumeDiv{
  padding:40px;
  height:100vh;
  overflow-y: scroll;
  background: rgba(195, 153, 127, 0.1);
  backdrop-filter:blur(15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .MarsFixed{
    position: fixed;
    font-family: 'Stint Ultra Expanded', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #FFFFFF;
    top: 2vh;
    margin: 0;
  }
  .developerFixed{
    font-family: 'Stint Ultra Condensed', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 35px;
    color: #FFFFFF;
    margin: 0;
    position: fixed;
    top: 6vh;
  }
  .resumeContainer{
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    .resume{
      width: 90vh;
      height: 100vh;
      filter: drop-shadow(0px 0px 50px rgba(39, 24, 17, 0.4));
    }
  }
  @media (max-width: 750px) {
    .MarsFixed{
      font-size: 2rem;
    }
    .developerFixed{
      font-size: 1.8rem;
      line-height: 4rem;
    }
    .resumeContainer{
      .resume{
        width: 40vh;
      }
    }

  }
}