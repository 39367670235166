.about{
  padding:40px;
  height:100vh;
  overflow-y: scroll;
  background: rgba(195, 153, 127, 0.1);
  backdrop-filter:blur(15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .MarsFixed{
    position: fixed;
    font-family: 'Stint Ultra Expanded', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #FFFFFF;
    top: 2vh;
    margin: 0;
  }
  .developerFixed{
    font-family: 'Stint Ultra Condensed', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 35px;
    color: #FFFFFF;
    margin: 0;
    position: fixed;
    top: 6vh;
  }
  .aboutContainer{
    box-sizing: border-box;
    background: rgba(101, 43, 33, 0.9);
    margin: 5rem 10rem 15rem 10rem;
    padding: 2rem;
    width: 80%;
    border: 10px solid white;
    border-radius: 8rem 0;
    p{
      font-family: 'Stint Ultra Condensed', cursive;
      color: #FFFFFF;
      font-size: 2rem;

    }
  }
  @media (max-width: 750px) {
    .MarsFixed{
      font-size: 2rem;
    }
    .developerFixed{
      font-size: 1.8rem;
      line-height: 4rem;
    }

    .aboutContainer{
      width: 88vw;
      margin: 5rem 0 0 0;
    }
  }
}
@media (max-width: 750px) {
  .about{
    overflow-x: hidden;
  }

}