.main{
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  .intro {
    height: 100vh;
    padding: 40px;
    scroll-snap-align: start;
    .Mars{
      font-family: 'Stint Ultra Expanded', cursive;
      font-style: normal;
      font-weight: 400;
      font-size: 3.75rem;
      line-height: 95px;
      text-align: center;
      color: #FFFFFF;
      margin: auto;
    }
    .developer{
      font-family: 'Stint Ultra Condensed', cursive;
      font-style: normal;
      font-weight: 400;
      font-size: 3rem;
      line-height: 71px;
      color: #FFFFFF;
      margin: auto;
    }
    .introButtons{
      a{
        cursor: pointer;
        text-decoration: none;
        font-family: 'Stint Ultra Condensed', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 42.5022px;
        color: #FFFFFF;
        background: transparent;
        border: none;
        margin: 30px auto 0;
        display: block;
        outline: none;
        width: auto;

        position: relative;
        transition: 0.3s;
        padding: 10px;
        &:before{
          content: '';
          border-bottom: 1px solid white;
          padding: 0 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 0;
          transition: 0.3s;
        }
        &:hover:before{
          width: 5%;
          padding: 0;
          &:active{
            color: #FFFFFF;
            background: white;
            transition: none;
          }

        }
      }
      button{
        cursor: pointer;
        text-decoration: none;
        font-family: 'Stint Ultra Condensed', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 42.5022px;
        color: #FFFFFF;
        background: transparent;
        border: none;
        margin: 30px auto 0;
        display: block;
        outline: none;
        width: auto;

        position: relative;
        transition: 0.3s;
        padding: 10px;
        &:before{
          content: '';
          border-bottom: 1px solid white;
          padding: 0 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 0;
          transition: 0.3s;
        }
        &:hover:before{
          width: 100%;
          padding: 0;
          &:active{
            color: #FFFFFF;
            background: white;
            transition: none;
          }

        }
      }
    }
  }

  .work {
    scroll-snap-align: start;
    padding:40px;
    height:100vh;
    overflow-y: scroll;
    background: rgba(195, 153, 127, 0.1);
    backdrop-filter:blur(15px);
    position: relative;
    display: flex;
    flex-direction: column;
    .upButton{
      position: sticky;
      top:87vh;

      transform: rotate(90deg);
      z-index: 100;
    }
    .MarsSticky{
      text-align: left;
      position: sticky;
      font-family: 'Stint Ultra Expanded', cursive;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      color: #FFFFFF;
      top: 2vh;
      width: 100%;
      margin: 0;
    }
    .developerSticky{
      text-align: left;
      font-family: 'Stint Ultra Condensed', cursive;
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      width: 100%;
      line-height: 35px;
      color: #FFFFFF;
      margin: 0;
      position: sticky;
      top: 6vh;
    }

    .workComponent {
      scroll-snap-align: center;
      box-sizing: border-box;
      background: rgba(101, 43, 33, 0.9);
      margin-bottom: 15rem;
      margin-left: 5vw;
      padding: 2rem;
      width: 90%;
      border: 10px solid white;
      border-radius: 8rem 0;
    }
    .workComponent:nth-child(even){
      background: rgba(38, 21, 13, 0.9);
      border-radius: 0 8rem;
      .description{
        text-align: right;
      }
    }
  }
  @media (max-width: 750px) {
    .work{
      overflow-x: hidden;
      .workComponent{
        margin-left: -2.5rem;
        width: 95vw;
        border-radius: 5rem 0;
      }
      .MarsSticky{
        display: none;
      }
      .developerSticky{
        display: none;
      }
      .workComponent:nth-child(even){
        .description{
          text-align: center;
        }
      }
    }

    }
}


