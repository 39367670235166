.workComponent{
  filter: drop-shadow(0px 0px 50px rgba(39, 24, 17, 0.4));
  .projectTitle{
    font-family: 'Stint Ultra Expanded', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 68px;
    margin: auto;
    color: #FFFFFF;
    text-align: center;
  }
  .projectTechRole{
    display: flex;
    font-family: 'Stint Ultra Condensed', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 42px;
    color: #EFE9CF;
    justify-content: space-around;
  }
  .projectDescriptionImg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .thumbnail > img{
      object-fit: cover;
      object-position: center;
      width: 90%;
      max-height: 400px;
    }
    p{
      text-align: left;
      font-family: 'Stint Ultra Condensed', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 161.39%;
      color: #FFFFFF;
      max-width: 50%;
    }
  }
  @media (max-width: 750px) {
    .projectTitle{
      font-size: 2.5rem;
    }
    .projectTechRole{
      font-size: 1.8rem;
      flex-direction: column;
    }
    .projectDescriptionImg{
      flex-direction: column;
      p{
        max-width: 95%;
        text-align: center;
      }
    }
    
  }

}