.App {
  text-align: center;
  font-family: 'Stint Ultra Condensed', cursive;
}
body{
  margin:0;
  padding:10px;
  min-height:100vh; background:url(https://images.unsplash.com/photo-1573588028698-f4759befb09a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8) no-repeat;
  background-size:cover;
  background-attachment:fixed;
  background-position:center center;
}
body::before {
  content:"";
  inset:0;
  position:fixed;
  border:10px solid #EFE9CF;
  z-index:1;
  pointer-events:none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

